<template>
	<div class="modal" @click.exact="closeModal">
		<div class="modalCont">


			<div class="modalHead">
				<div>
					<div>{{ dat.name_lat }}</div>
					<div>{{ dat.name_de}}</div>
				</div>
			</div>

			<div class="modalMain">

				<div class="detailsCont">
					
					<div class="img"><img :src="$store.state.server + '/img/pk/' + dat.img_full" width="200" height="150"></div>

					<div class="items">
						<div class="item">
							<!-- <div></div> -->
							<div class="description">{{ dat.description }}</div>
						</div>

						<div v-if="dat.preis" class="item">
							<div title="Preis enthält 13% Mwst.">Preis *</div>
							<div>€ {{ dat.preis }}</div>
						</div>

						<div v-if="dat.bluetezeit" class="item">
							<div>Blütezeit</div>
							<div>{{ dat.bluetezeit }}</div>
						</div>

						<div v-if="dat.bluetenfarbe" class="item">
							<div>Blütenfarbe</div>
							<div>{{ dat.bluetenfarbe }}</div>
						</div>

						<div v-if="dat.standort" class="item">
							<div>Standort</div>
							<div>{{ dat.standort }}</div>
						</div>

						<div v-if="dat.groesse" class="item">
							<div>Größe</div>
							<div>{{ dat.groesse }}</div>
						</div>

						<div v-if="dat.liefergroesse" class="item">
							<div>Liefergroesse/ Stückz.</div>
							<div>{{ dat.liefergroesse }}</div>
						</div>

						<div v-if="dat.sonstig" class="item">
							<div>Sonstiges</div>
							<div>{{ dat.sonstig }}</div>
						</div>
						
					</div>
				</div>

			</div>

			<div class="modalFooter">
				

			</div>
		</div>
	</div>
</template>

<script>


	export default {
		name: 'PkDetails',
		components: {

		},
		props:[
		'par'

		],
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
				dat : this.par.data,

			}
		}, // end data
		methods: {
			closeModal(){
				this.$parent.pkDetails.visible = false;
			},



		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.modal {
	position: fixed;
	top: 0;
	left: 0rem;
	background-color: rgb(0,0,0, .5);
	width: 100vw;
	height: 100vh;
	z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;

		& > .modalCont {
			background-color: white;
			width: 60rem;
			padding: 1rem;

			border-radius: 10px;
			box-shadow: 2px 2px 2px #555;
			margin-bottom: 12rem;

				@media (max-width: 600px) { 
			margin-bottom: 0;
			width: 80%;
				}

		}

		& .modalMain {
			min-height: 7rem;
			width: 80vw;
			max-width: 800px;
			text-align: center;
			margin: 0 auto;

		}

		& .modalHead {
			text-align: center;

			border-bottom: 1px solid silver;
			padding: 0.3rem 1rem;
			margin-bottom: 1.4rem;

			& div > div:first-child {
			font-family: f2;
			font-size: 1.4rem;
			color: var(--c12);
			font-weight: bold;
			letter-spacing: 1.2px;

			@media (max-width: 600px) {
			font-size: 1.3rem;
				


			}

			& div > div:nth-child(2) {
				// font-family: f2;
				font-size: 1.2rem;
				padding: .4rem;
			}
			@media (max-width: 600px) {
				font-size: 1.3rem;
				
			}


		}

		}
	& .modalFooter {
		display: flex;
		justify-content: center;
		align-items: baseline;
		
		& div {
			margin: 1rem;
			min-width: 12rem;

			& button {
				width: 100%;
			}
		}
	}

	.detailsCont {
		display: flex;
		align-items: center;

		& .img {
			margin-right: 2rem;
			& img {
				// border: 1px solid silver;
				border-radius: 6px;
				box-shadow: 1px 1px 2px gray;
			}

		}
		& .items {
			width: 100%;
			text-align: left;
			display: flex;
			flex-direction: column;
			font-size: .9rem;

			& > div:first-child {
				// border-bottom: 1px solid silver;
				margin-bottom: 1rem;

			}

			& .item {
				display: flex;
				& div:nth-child(1){
					min-width: 9rem;
					text-align: right;
					padding-right: 1rem;
					color: gray;

				}		

				& div.description {
					text-align: left;
					font-size: 1.1rem;
					color: #5b5b5b;


						
					
				}		
			}

		}
		
	}

	@media (max-width: 600px) { 
		.detailsCont {
			flex-direction: column;

			& .img {
				margin: 0;
			}

		}

	}
}


</style>
