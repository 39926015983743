<template>
	<div class="container">

<!-- 		<div class="pkSubNav">
			<div v-for="(k,i) in pkKats" v-bind:class="[selKat === i ? 'active' : '']" @click="getIndex(i)">{{ k.text }}</div>
		</div>
 -->
 <pkDetails v-if="pkDetails.visible" :par="pkDetails"></pkDetails>
<div class="pInfo">Alle angezeigten Preise sind Bruttopreise inklusive 13% Mehrwertsteuer.</div>

 <div class="pkSubNav">

 	<div class="selBox">
 		<div>wählen Sie eine Kategorie</div>
 		<div>
 			<select v-model="selKat" @change="changeSelKat">
 				<option v-for="(k, i) in pkKats" :value="k.id" :key="i" >{{ k.text }}</option>
 			</select>
 		</div>
 	</div>

 	<div class="filter">
 		<div>Filter</div>
 		<div  class="icon exit">
 			<input type="text" @keyup="filterPkList" v-model="pkFilterInput">
 			<div @click="resetPkFilter"><svg><use xlink:href="/sys_img/icons.svg#exit"/></svg></div>
 		</div>
 	</div>

 </div>


		<div class="pkLines">

			<div  v-for="(v,i) in pkFilter">
				<div v-if="v.name_lat" class="pkLine">
					<div @click="showPkDetails(i)"><img :src="$store.state.server + '/img/pk/' + v.img_thumbnail" width="70" alt=""></div>
					<div class="name">
						<div class="lat">{{ v.name_lat }}</div>
						<div class="de">{{ v.name_de }}</div>
						<div class="deDesc">{{ v.description }}</div>
					</div>
					<div title="Preis enthält 13% Mwst.">{{ v.preis }} €</div>
				</div>
			</div>

		</div>

		</div>
</template>

<script>
	import axios from 'axios'
	import  pkDetails from "@/components/PkDetails.vue";
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'Pflanzenkatalog',
		components: {
			pkDetails,
		},
		mounted(){
			this.getIndex();


		}, // end mounted 
		data: 
		function(){
			return {
				pkKats: [],
				selKat: 4,
				pk: {},
				pkFilter: {},
				pkFilterInput: '',
				pkDetails: {visible: false, data: {}},
			}
		}, // end data
		methods: {

			showPkDetails(key){
				this.pkDetails.data = this.pkFilter[key];
				this.pkDetails.visible = true;
			},
			changeSelKat(){
				localStorage.setItem("lastSelKat", this.selKat);
				this.getIndex();
			},

			getIndex(){

				this.resetPkFilter();

				if (localStorage.getItem("lastSelKat")) {
					this.selKat = localStorage.getItem("lastSelKat");
				} else {
					localStorage.setItem("lastSelKat", this.selKat);
				}

				let self = this;
				let url = this.$store.state.server + '/api/getPkIndex.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { kat: this.selKat },
				})
				.then(function (resp) {

					self.pkKats = resp.data.data[0];
					self.pk = self.pkFilter = resp.data.data[1];

				});

			},
						resetPkFilter(){
				this.pkFilterInput = '';
				this.filterPkList(); 
			},
			filterPkList(){
				let st = this.pkFilterInput;
						this.pkFilter = [];

				for (const i in this.pk) {

					var re = new RegExp(st, 'i');


					let tagD = this.pk[i].name_de;
					let tagL = this.pk[i].name_lat;
					if ((tagD !== null && tagD.match(re))  || (tagL !== null && tagL.match(re))) {


						this.pkFilter.push(this.pk[i]);
					}
				}

			},



		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.container {
	position: relative;
	margin-top: 1rem;
}





.pkSubNav {
	background-color: #fff;
	font-size: .9rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	z-index: -1;
	// width: 87%;
	// top: 8rem;

	& div	{
		flex: 0 0 50%;

		& > div {
			color: gray;
		}
		&.selBox {

			
			& select{
				width: 23rem;
				appearance: none;
				border: 1px solid silver;
				padding: 0.5rem;
				border-radius: 6px;
				background-color: white;
				background-image: url('/sys_img/sel-arrow-left.svg');
				background-repeat: no-repeat;
				background-position: 21.6rem;
				cursor: pointer;
				
				& option {
				}
			}
		}
		&.filter {
			& input {
				border: 1px solid silver;
				padding: 0.4rem;
				border-radius: 6px;
				background-color: white;
				background-image: url('/sys_img/lupe.svg');
				background-repeat: no-repeat;
				background-position: 11.9rem;
				height: 1.5rem;
			}
		}

		&:nth-child(2){

				}
		&:hover {
			// background-color: var(--c12);
			// color: white;
		}
	}
	& div.active {
		// background-color: #587d5c;
		// color: white;
		// font-weight: bold;
	}
}

@media (max-width: 600px) { 
	.pkSubNav { 
		flex-direction: column;

		& .filter { 
			display: none;
		}
	}

}



.pkLines {
	margin-top: 1rem;
	margin-bottom: 3rem;
}

.pkLine {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	// border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;

	&:last-child {

	}

	& > div:nth-child(1){
		width: 90px;
		margin: .5rem 1rem;
		& img {
			border-radius: 50%;
			box-shadow: 2px 1px 3px #b9b9b9;
			cursor: pointer;

		}
	}
	& div:nth-child(2){
		width: 42rem;
		padding-right: 1rem;

		&  div {
			width: 100%;

		}

		& div.lat {
			font-family: f2;
			font-size: 1.2rem;
			color: var(--c13);
		}
		& div.de {
			font-size: 1.1rem;
			font-weight: bold;
			color: gray;
		}		
		& div.deDesc {
			font-size: small;
		}
	}
	& div:nth-child(3){
		align-self: center;
	}
}


@media (max-width: 600px) { 
	.pkLines {
		margin-top: 1rem;

	}

	.pkLine {
		padding: .4rem 0;
		& div:nth-child(2){

			& div.lat {
				font-size: 1rem;
			}

			& div.de {
				font-size: 1rem;
				font-weight: bold;
				color: gray;
			}	
					& div.deDesc {
		text-align: left;
		}

		}
	}

	
}


.icon.exit {

	position: relative;

	& div {
		position: absolute;
		left: 13.4rem;
		top: 0.1rem;
		stroke: #b97777;
		scale: 0.6;

		cursor: pointer;
		& svg {
			width: 2rem;
			height: 2rem;

		}
	}
	
}

.pInfo {
	// border-bottom: 1px solid silver;
	margin: 1rem 0 0.8rem;
	font-size: 0.9rem;
	background: #eee;
	padding: .1rem 1rem;
	border-radius: 6px;
}


</style>
